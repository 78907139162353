@font-face {
  font-family: 'OpenSans-Regular';
  src: url('./fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-Italic';
  src: url('./fonts/OpenSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url('./fonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  src: url('./fonts/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-Light';
  src: url('./fonts/OpenSans-Light.ttf') format('truetype');
}


@font-face {
  font-family: 'Futura-Bold';
  src: url('./fonts/Futura-Bold.ttf') format('truetype');
}
